import React from "react";
// import logo1 from "../../images/aboutusassets/gokenIndia/clients/faraday.png";
import logo2 from "../../images/aboutusassets/gokenIndia/clients/standex.png";
import logo3 from "../../images/aboutusassets/gokenIndia/clients/dagmy_motor.png";
// import logo4 from "../../images/aboutusassets/gokenIndia/clients/spec_sintered.png";
import logo5 from "../../images/aboutusassets/gokenIndia/clients/dnkr.png";
import logo6 from "../../images/aboutusassets/gokenIndia/clients/key_performance.png";
import logo7 from "../../images/aboutusassets/gokenIndia/clients/parishodhana.png";
import LogoTicker from "../LogoTicker";
import { Fade } from "react-reveal";

const OurClients = ({ smallHeading, bigHeading }) => {
  const section1 = [
    // logo1,
    logo2,
    logo3,
    // logo4,
    logo5,
    logo6,
    logo7,
  ];

  return (
    <div className="goken_india__our_client_section w-90-wrapper p-top-4em">
      <Fade cascade duration={1000}>
        <div className="gix-client-header-wrapper">
          <h3 className="title no-margin">{smallHeading}</h3>
          <h2 className="heading no-margin titlecase-text">{bigHeading}</h2>
        </div>
        <div className="clients-wrapper">
          <LogoTicker>
            {section1.map((logo) => (
              <div className="logo-image-container">
                <img src={logo} alt="goken india clients| about goken india" />
              </div>
            ))}
          </LogoTicker>
        </div>
        <div className="text-wrapper text-center">
          <p className="para">
            Goken India supports dev for OEM globally, for confidentiality we
            are only showing some of the Client logos
          </p>
        </div>
      </Fade>
    </div>
  );
};

export default OurClients;
