import React from "react";
import bgImage from "../../images/gokenIndia/goken-in-bg.png";
import mobileImage from "../../images/gokenIndia/bg-gix.jpg";
import { Fade } from "react-reveal";
const Header = ({ headingOne, headingTwo, first, second }) => {
  return (
    <div className="india__header-wrapper">
      <div className="header-container">
        <div
          className="background-image-container"
          style={{ background: `url(${bgImage})` }}
        >
          <div className="background-image-overlay-container"></div>
        </div>
        <Fade duration={1000} cascade>
          <div className="content-wrapper">
            <div className="left-header-wrapper">
              <div className="content-headings-wrapper">
                <h1
                  className="page-heading titlecase-text"
                  style={{ color: "white", lineHeight: 1 }}
                >
                  {headingOne}
                </h1>{" "}
                <br />
                <h2
                  className="page-heading titlecase-text"
                  style={{ color: "#25aad1", lineHeight: 0 }}
                >
                  {headingTwo}
                </h2>
              </div>
            </div>

            <div className="right-header-wrapper">
              {/* <Fade duration={1500} delay={1000} cascade> */}
              <div className="title-wrapper" style={{ color: "white" }}>
                <h4 className="para">{first.smallTitle}</h4>
                <h3 className="heading">{first.bigTitle}</h3>
              </div>
              <div className="title-wrapper" style={{ color: "white" }}>
                <h4 className="para">{second.smallTitle}</h4>
                <h3 className="heading titlecase-text">{second.bigTitle}</h3>
              </div>
              {/* </Fade> */}
            </div>
          </div>
        </Fade>
      </div>
      <div className="gix-mobile-wrapper">
        <div
          className="mobile-header-wrapper"
          style={{ background: `url(${mobileImage})` }}
        >
          <div className="background-image-overlay-container"></div>
          <div className="content-wrapper" style={{ padding: "25px 0 0 0" }}>
            <Fade duration={1000}>
              <h2
                className="page-heading titlecase-text"
                style={{
                  color: "white",
                  margin: "0 auto",
                  padding: "25px 0 0 0",
                }}
              >
                {headingOne}
                <label
                  style={{ color: "#25aad1", marginLeft: "10px" }}
                >{` ${headingTwo}`}</label>
              </h2>
            </Fade>
            {/* <h3
              className="page-heading titlecase-text"
              style={{ color: "#25aad1" }}
            >
              {headingTwo}
            </h3> */}
          </div>
          <Fade duration={1000}>
            <div
              className="title-wrapper"
              style={{
                color: "white",
                textAlign: "center",
                paddingBottom: "25px",
              }}
            >
              <h4 className="para">{second.smallTitle}</h4>
              <h3 className="heading titlecase-text">
                <i>{second.bigTitle}</i>
              </h3>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Header;
