import React from "react";
import SwapnilsImage from "../../images/aboutusassets/gokenIndia/gix_swapnil.png";
import ArrowWhite from "../../images/aboutusassets/gokenIndia/arrowWhite.png";
import { Fade } from "react-reveal";

const AboutImage = ({ media }) => {
  return (
    <div className="lifeat-goken-section-wrapper">
      <Fade cascade duration={1000}>
        <video src={media.url} loop autoPlay muted controls={true} />
        <a href="/experiencelifeatgoken/" className="lifeatgoken-cta">
          Life at Goken India <img src={ArrowWhite} alt="lifeatgokenindia" />
        </a>
      </Fade>
    </div>
  );
};

export default AboutImage;
